<template>
  <div class="main-p pt-200">
    <div class="text-center size-60 text-bold" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('通证数字化生态1') }}</div>
    <div class="size-24 text-center mt-78 line-height-48" style="color: #9EABA3;" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">
        {{ $t('通证数字化生态...') }}
    </div>
    <div class="tokenLine mt-100"></div>
    <div class="flex ac pt-28 pb-35 text-center size-22" style="color: #00FF5A;" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
        <div class="flex-1 flex-s">{{ $t('领域') }}</div>
        <div class="flex-1 flex jb ac flex-s">
            <div class="tokenLine1"></div>
            <div>{{ $t('比例') }}</div>
            <div class="tokenLine1"></div>
        </div>
        <div class="flex-2">{{ $t('用途') }}</div>
    </div>
    <div class="tokenLine2"></div>
    <div class="flex ac pt-28 pb-35 text-center size-16" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
        <div class="flex-1 flex-s">{{ $t('种子机合约矿池') }}</div>
        <div class="flex-1 flex jb ac flex-s">
            <div class="tokenLine1"></div>
            <div>5%</div>
            <div class="tokenLine1"></div>
        </div>
        <div class="flex-2">
            <div class="pl-10">{{ $t('用途一') }}</div>
        </div>
    </div>
    <div class="tokenLine2"></div>
    <div class="flex ac pt-28 pb-35 text-center size-16" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
        <div class="flex-1 flex-s">{{ $t('VIP通道合约矿池') }}</div>
        <div class="flex-1 flex jb ac flex-s">
            <div class="tokenLine1"></div>
            <div>3%</div>
            <div class="tokenLine1"></div>
        </div>
        <div class="flex-2">
            <div class="pl-10">{{ $t('用途二') }}</div>
        </div>
    </div>
    <div class="tokenLine2"></div>
    <div class="flex ac pt-28 pb-35 text-center size-16" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
        <div class="flex-1 flex-s">{{ $t('生态Dao合约矿池') }}</div>
        <div class="flex-1 flex jb ac flex-s">
            <div class="tokenLine1"></div>
            <div>7%</div>
            <div class="tokenLine1"></div>
        </div>
        <div class="flex-2">
            <div class="pl-10">{{ $t('用途三') }}</div>
        </div>
    </div>
    <div class="tokenLine2"></div>
    <div class="flex ac pt-28 pb-35 text-center size-16" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
        <div class="flex-1 flex-s">{{ $t('基金会合约矿池') }}</div>
        <div class="flex-1 flex jb ac flex-s">
            <div class="tokenLine1"></div>
            <div>5%</div>
            <div class="tokenLine1"></div>
        </div>
        <div class="flex-2">
            <div class="pl-10">{{ $t('用途四') }}</div>
        </div>
    </div>
    <div class="tokenLine2"></div>
    <div class="flex ac pt-28 pb-35 text-center size-16" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
        <div class="flex-1 flex-s">{{ $t('算力合约矿池') }}</div>
        <div class="flex-1 flex jb ac flex-s">
            <div class="tokenLine1"></div>
            <div>80%</div>
            <div class="tokenLine1"></div>
        </div>
        <div class="flex-2">
            <div class="pl-10">{{ $t('用途五') }}</div>
        </div>
    </div>
    <div class="tokenLine"></div>
  </div>
</template>

<script>
export default {
    name:"TokenDig"
}
</script>

<style lang="scss">
.tokenLine{
    width: 100%;
    height: .04rem;
    background: linear-gradient(to right, #000000, #43EA80 70%, #000000);
}
.tokenLine1{
    width: .04rem;
    height: .38rem;
    border-radius: .02rem;
    background: linear-gradient(0deg, #43EA80 0%, #38F8D4 100%);
}
.tokenLine2{
    width: 100%;
    height: .02rem;
    background: linear-gradient(to right, #000000, #213A2B 60%, #000000);
}
</style>