<template>
  <div class="botbar size-12">Copyright ◎ 2024 ARK FINTECH GROUP INC.</div>
</template>

<script>
export default {
    name:"BotBar"
}
</script>

<style lang="scss">
.botbar{
    padding-top: .2rem;
    padding-bottom: .2rem;
    background-color: #000000;
    color: #595B59;
    text-align: center;
}
</style>