import Vue from 'vue'
import App from './App.vue'
import { getLang, jump } from '@/utils/storage'

Vue.config.productionTip = false

Vue.prototype.$jump = jump

/**
 * ElementUI
 */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueAnimateOnScroll from 'vue-animate-onscroll';
Vue.use(VueAnimateOnScroll)
import "animate.css";

/**
 * 国际化
 */
import messages from '@/locale'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: getLang(),
  messages
})
export function $t(key){
  return i18n.tc(key)
}

/**
 * 使用Vuex
 */
import store from '@/store';
Vue.prototype.$store = store

new Vue({
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
