<template>
  <div class="contact main-p flex jb flex-w">
    <div style="width: 5.5rem;" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
        <div class="size-32 text-bold mb-50">{{ $t('联系我们') }}</div>
        <div class="size-18 line-height-36" style="color: #525554;">{{ $t('联系我们...') }}</div>
        <div class="flex mt-40">
            <img src="@/assets/con0.png" class="img-46 mr-30 cur" @click="$jump(links[0])">
            <img src="@/assets/con1.png" class="img-46 mr-30 cur" @click="$jump(links[1])">
            <img src="@/assets/con2.png" class="img-46 mr-30 cur" @click="$jump(links[2])">
            <img src="@/assets/con3.png" class="img-46 cur" @click="$jump(links[3])">
        </div>
    </div>
    <div class="botRight" v-animate-onscroll.repeat="'animate__animated animate__slideInLeft'">
        <div class="size-32 text-bold">{{$t('信息')}}</div>
        <div class="flex ac mt-40">
            <div class="flex jb ac botItem cur" @click="$jump(whitePaper)">
                <div class="flex ac">
                   <img src="@/assets/info.png" class="info">
                   <div class="size-20 ml-20">{{ $t('白皮书') }}</div>
                </div>
                <img src="@/assets/arrowRight.png" class="img-20">
            </div>
            <div class="flex jb ac botItem ml-20 cur" @click="$jump(report)">
                <div class="flex ac">
                   <img src="@/assets/info.png" class="info">
                   <div class="size-20 ml-20">{{ $t('审计报告') }}</div>
                </div>
                <img src="@/assets/arrowRight.png" class="img-20">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"ContactUs",
    data(){
        return {
            links:[
                "https://www.coingecko.com/en/coins/ark-fintech",
                "https://t.me/ArkFintechChannel",
                "https://x.com/arkfintechgroup",
                "mailto:arkfintech1688@gmail.com"
            ],
            whitePaper:'https://www.arkfintech.io/Whitepaper.pdf',
            report:'https://www.arkfintech.io/ARKSmartContractAuditReport.pdf'
        }
    },
    mounted(){
        
    }
}
</script>

<style lang="scss">
.contact{
    background-color: #011309;
    padding-top: 1rem;
    padding-bottom: .9rem;
}
.info{
    width: .18rem;
    height: .2rem;
}
.botItem{
    width: 2.6rem;
    height: .48rem;
    background-color: #063A1C;
    padding: 0 .2rem;
}
@media (max-width:768px) {
    .botRight{
        margin-top: .4rem;
    }
    .botItem{
        max-width: 45% !important;
    }
}
</style>