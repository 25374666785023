<template>
  <div class="app">
    <div id="Home"></div>
    <nav-bar @scroll="scrollTo"></nav-bar>

    <div id="About"></div>
    <about-us></about-us>

    <div id="Core"></div>
    <core-strengths></core-strengths>

    <div id="Product"></div>
    <product-service></product-service>

    <div id="Tech"></div>
    <tech-capab></tech-capab>

    <div id="Token"></div>
    <token-dig></token-dig>

    <div id="Plan"></div>
    <develop-plan></develop-plan>

    <div id="Part"></div>
    <part-ship></part-ship>

    <div id="Contact"></div>
    <contact-us></contact-us>

    <bot-bar></bot-bar>

  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import AboutUs from './components/AboutUs.vue';
import CoreStrengths from './components/CoreStrengths.vue';
import ProductService from './components/ProductService.vue';
import TechCapab from './components/TechCapab.vue';
import TokenDig from './components/TokenDig.vue';
import DevelopPlan from './components/DevelopPlan.vue';
import PartShip from './components/PartShip.vue';
import ContactUs from './components/ContactUs.vue';
import BotBar from './components/BotBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    AboutUs,
    CoreStrengths,
    ProductService,
    TechCapab,
    TokenDig,
    DevelopPlan,
    PartShip,
    ContactUs,
    BotBar
  },
  methods:{
    scrollTo(data) {
      if (data) {
        this.$nextTick(() => {
          const element = document.getElementById(data);
          element.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
.app{
  width: 100vw;
  overflow-x: hidden;
  background-image: url("@/assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.color-main{
  color: #00FF5A;
}
.el-carousel__mask{
  background-color: transparent !important;
}
</style>
