<template>
  <div class="about">
    <div class="" v-animate-onscroll.repeat="'animate__animated animate__bounceInLeft'">
        <img src="@/assets/1.png" class="img1">    
    </div>
    
    <div class="text-center size-60 text-bold" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{$t('公司简介')}}</div>
    <div class="aboutP size-24 main-p" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('Ark Fintech...') }}</div>

    <div class="text-center size-60 text-bold mt-200 mb-156" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('新闻媒体中心') }}</div>

    <div class="pos-rel">
        <video controls src="https://www.arkfintech.io/video.mp4" poster="../assets/videoPoster.png" class="aboutVideo"></video>
        <img src="@/assets/videoBg.png" class="aboutLine">
    </div>
    
    <div class="main-p mt-100 pos-rel">
        <div class="aboutTab flex main-p cur pc">
            <div class="tab mr-6" :class="current==0?'tabAct':''" @click="tabChange(0)"></div>
            <div class="tab" :class="current==1?'tabAct':''" @click="tabChange(1)"></div>
        </div>
        <div class="main-p aboutArrow">
            <img src="@/assets/arrow.png" class="arrow cur" :class="current==1?'aboutArrowRight':''" @click="tabChange(current==1?0:1)">    
        </div>
        
        <div class="aboutItem animate__animated" :class="leftAni" :style="{'opacity':current==0?1:0}">
            <div class="size-28 text-bold" v-animate-onscroll.repeat="'animate__animated animate__pulse'">{{ $t('欧美媒体报道') }}</div>
            <div class="size-24 mt-80" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
                <div class="mb-40">1、The American Reporter</div>
                <div class="color-main lineTh cur" @click="$jump(links[0])">{{ links[0] }}</div>
            </div>
            <div class="size-24 mt-80" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
                <div class="mb-40">2、Vents Magazine</div>
                <div class="color-main lineTh cur" @click="$jump(links[1])">{{ links[1] }}</div>
            </div>
            <div class="size-24 mt-80" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
                <div class="mb-40">3、 Yahoo Perspective</div>
                <div class="color-main lineTh cur" @click="$jump(links[2])">{{ links[2] }}</div>
            </div>
        </div>
        <div class="aboutItem animate__animated" :class="rightAni" :style="{'opacity':current==1?1:0}">
            <div class="size-28 text-bold" v-animate-onscroll.repeat="'animate__animated animate__pulse'">{{ $t('欧美媒体报道') }}</div>
            <div class="size-24 mt-80" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
                <div class="mb-40">4、American fortune daily</div>
                <div class="color-main lineTh cur" @click="$jump(links[3])">{{ links[3] }}</div>
            </div>
            <div class="size-24 mt-80" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
                <div class="mb-40">5、American news network</div>
                <div class="color-main lineTh cur" @click="$jump(links[4])">{{ links[4] }}</div>
            </div>
            <div class="size-24 mt-80" v-animate-onscroll.repeat="'animate__animated animate__backInRight'">
                <div class="mb-40">6、New York City Morning Post</div>
                <div class="color-main lineTh cur" @click="$jump(links[5])">{{ links[5] }}</div>
            </div>
        </div>
    </div>

    <div class="main-p" style="margin-top: 9rem;">
        <div class="text-center size-60 text-bold" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('权威资质') }}</div>
        <div class="size-24 mt-60 text-center aboutTip" v-animate-onscroll.repeat="'animate__animated animate__bounce'">{{ $t('资质一') }}<br>{{ $t('资质二') }}</div>
        <div class="pos-rel mt-60">
            <el-carousel indicator-position="none" :interval="4000" type="card" height="70vw" @change="onBookChange">
                <el-carousel-item v-for="(item,index) in 6" :key="index">
                    <div class="flex-center book">
                        <img :class="{'bookopc':bookCurrent!=index}" :src="require(`@/assets/book${index+1}.png`) ">    
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div v-animate-onscroll.repeat="'animate__animated animate__pulse'">
            <img src="@/assets/bookBot.png" class="bookBot">    
            </div>
            
        </div>
        <div style="height: 1rem;"></div>
    </div>
  </div>
</template>

<script>
export default {
    name:"AboutUs",
    data(){
        return {
            current:0,
            leftAni:'',
            rightAni:'',

            bookCurrent:0,

            links:[
                "https://www.theamericanreporter.com/ark-fintech-movebit-bitslab-the-web3-power-of-security-auditing/",
                "https://ventsmagazine.com/2024/10/11/interview-with-senior-management-of-ark-investment-management-llc/",
                "http://yahoosee.com/info/ARK-FINTECH--MoveBit--BitsLab-The-WEB3-Power-of-Security-Auditing-2sbn",
                "http://fortunetime.us/info/ARK-FINTECH--MoveBit--BitsLab-The-WEB3-Power-of-Security-Auditing-gpo0oq0nkb.html",
                "http://usatnews.com/info/ARK-FINTECH--MoveBit--BitsLab-The-WEB3-Power-of-Security-Auditing-l4bnosne0b.html",
                "http://nycmorning.com/info/ARK-FINTECH--MoveBit--BitsLab-The-WEB3-Power-of-Security-Auditing-2qbe1qkee1.html"
            ],

        }
    },
    mounted(){
       
    },
    methods:{
        tabChange(index){
            if(this.current==index)return
            this.current = index
            if(this.current==1){
                this.leftAni = 'animate__fadeOutLeft'
                this.rightAni = 'animate__fadeInRight'
            }else{
                this.leftAni = 'animate__fadeInLeft'
                this.rightAni = 'animate__fadeOutRight'
            }
        },
        onBookChange(e){
            this.bookCurrent = e
        }
    }
}
</script>

<style lang="scss">
.about{
    position: relative;
    padding-top: 2rem;
    .img1{
        width: 8.67rem;
        position: absolute;
        top: .57rem;
        left: 0;
    }
    .aboutP{
        margin-top: 1rem;
        color: #ADBCB3;
        line-height: .48rem;
    }
    .aboutVideo{
        width: 100vw;
        position: relative;
        z-index: 100;
    }
    .aboutLine{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .aboutTab{
        position: absolute;
        top: .1rem;
        right: 0;
        z-index: 10;
        .tab{
            width: .6rem;
            height: .1rem;
            border-radius: 0.05rem;
            background-color: rgba(255,255,255,0.2);
            transition: all 0.3s;
        }
        .tabAct{
            background-color: #00FF5A !important;
            width: .3rem !important;
        }
    }
    .aboutItem{
        position: absolute;
    }
    .aboutArrow{
        position: absolute;
        top: 2.5rem;
        right: 0;
        z-index: 10;
        .arrow{
            width: .88rem;
            height: .88rem;
            transition: all 0.3s;
        }
    }
    .aboutArrowRight{
        transform: rotateY(180deg);
    }
    .aboutTip{
        color: #9EABA3;
        line-height: .48rem;
    }
    .book{
        width: 100%;
        height: 100%;
    }
    .bookopc{
        opacity: 0.5;
    }
    .bookBot{
        width: 100%;
        position: absolute;
        bottom: -1rem;
    }
}
</style>