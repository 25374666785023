<template>
  <div class="pt-200 pb-200">
    <div class="main-p mb-100">
        <div class="text-center size-60 text-bold mb-60" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('合作伙伴') }}</div>
        <div class="size-24 line-height-48 text-center" style="color: #9EABA3;" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">
            {{ $t('合作伙伴...') }}
        </div>    
    </div>
    <partner-move-left></partner-move-left>
    <partner-move-right class="mt-60"></partner-move-right>
  </div>
</template>

<script>
import PartnerMoveLeft from './PartnerMoveLeft.vue';
import PartnerMoveRight from './PartnerMoveRight.vue';
export default {
    name:"PartShip",
    components:{
        PartnerMoveLeft,
        PartnerMoveRight
    }
}
</script>

<style lang="scss">
.part-gap{
    width: 100%;
    height: 1rem;
}
</style>