<template>
  <div class="main-p pt-200">
    <div class="text-center size-60 text-bold" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('产品与服务1') }}</div>
    <div class="flex flex-w mt-100">
        <div class="prodItem flex-s prodCard">
            <img src="@/assets/prod1.png" class="prodImg" v-animate-onscroll.repeat="'animate__animated animate__rotateIn'">
            <div class="size-24 text-bold mb-40" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('WEB3 数字银行') }}</div>
            <div class="size-16 color-7d line-height-32" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('WEB3 数字银行...') }}</div>
        </div>
        <div class="prodItem flex-s prodCard1">
            <img src="@/assets/prod2.png" class="prodImg" v-animate-onscroll.repeat="'animate__animated animate__rotateIn'">
            <div class="size-24 text-bold mb-40" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('去中心化金融网络') }}</div>
            <div class="size-16 color-7d line-height-32" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('去中心化金融网络...') }}</div>
        </div>
        <div class="prodItem flex-s prodCard1">
            <img src="@/assets/prod3.png" class="prodImg" v-animate-onscroll.repeat="'animate__animated animate__rotateIn'">
            <div class="size-24 text-bold mb-40" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('加密全球购') }}</div>
            <div class="size-16 color-7d line-height-32" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('加密全球购...') }}</div>
        </div>
        <div class="prodItem flex-s prodCard">
            <img src="@/assets/prod4.png" class="prodImg" v-animate-onscroll.repeat="'animate__animated animate__rotateIn'">
            <div class="size-24 text-bold mb-40" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('区块链游戏生态') }}</div>
            <div class="size-16 color-7d line-height-32" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('区块链游戏生态...') }}</div>
        </div>
        <div class="prodItem flex-s prodCard">
            <img src="@/assets/prod5.png" class="prodImg" v-animate-onscroll.repeat="'animate__animated animate__rotateIn'">
            <div class="size-24 text-bold mb-40" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('WEB3 娱乐') }}</div>
            <div class="size-16 color-7d line-height-32" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('WEB3 娱乐...') }}</div>
        </div>
        <div class="prodItem flex-s prodCard1">
            <img src="@/assets/prod6.png" class="prodImg" v-animate-onscroll.repeat="'animate__animated animate__rotateIn'">
            <div class="size-24 text-bold mb-40" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('加密社交') }}</div>
            <div class="size-16 color-7d line-height-32" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('加密社交...') }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"ProductService"
}
</script>

<style lang="scss">
.prodItem{
    width: 50%;
    text-align: center;
}
.prodCard{
    background-color: #011309;
}
.prodCard1{
    background-color: #063A1C;
}
@media (min-width:768px) {
    .prodItem{
        padding: 1.5rem .6rem .6rem .6rem;
        .prodImg{
            width: 1.54rem;
            height: 1.54rem;
            margin-bottom: .96rem;
        }
    }
}
@media (max-width:768px) {
    .prodItem{
        padding: .3rem .1rem .1rem .1rem;
        .prodImg{
            width: .54rem;
            height: .54rem;
            margin-bottom: .3rem;
        }
    }
}
</style>