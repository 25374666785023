<template>
  <div class="main-p pt-200">
    <div class="text-center size-60 text-bold" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('发展规划1') }}</div>
    <div class="planItem mt-120">
        <div class="planCircle flex-center">
            <div class="planDot"></div>
        </div>
        <div class="size-20" style="color: #00FF5A;">{{ $t('计划一') }}</div>
        <div class="size-20 mt-30">{{ $t('计划一标题') }}</div>
        <div class="size-18 mt-28 line-height-40" style="color: #9EABA3;" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
            - Q2/2024: {{ $t('计划一1') }}<br>
            - Q2/2024: {{ $t('计划一2') }}<br>
            - Q3/2024: {{ $t('计划一3') }}<br>
            - Q4/2024: {{ $t('计划一4') }}<br>
            - Q4/2024: {{ $t('计划一5') }}<br>
            - Q4/2024: {{ $t('计划一6') }}
        </div>
    </div>
    <div class="planItem">
        <div class="planCircle flex-center">
            <div class="planDot"></div>
        </div>
        <div class="size-20" style="color: #00FF5A;">{{ $t('计划二') }}</div>
        <div class="size-20 mt-30">{{ $t('计划二标题') }}</div>
        <div class="size-18 mt-28 line-height-40" style="color: #9EABA3;" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
            - Q1/2025: {{ $t('计划一1') }}.<br>
            - Q1/2025: {{ $t('计划二2') }}<br>
            - Q2/2025: {{ $t('计划二3') }}<br>
            - Q2/2025: {{ $t('计划二4') }}<br>
            - Q2/2025: {{ $t('计划二5') }}<br>
            - Q2/2025: {{ $t('计划二6') }}
        </div>
    </div>
    <div class="planItem" style="border: none;padding-bottom: 0;">
        <div class="planCircle flex-center">
            <div class="planDot"></div>
        </div>
        <div class="size-20" style="color: #00FF5A;">{{ $t('计划三') }}</div>
        <div class="size-20 mt-30">{{ $t('计划三标题') }}</div>
        <div class="size-18 mt-28 line-height-40" style="color: #9EABA3;" v-animate-onscroll.repeat="'animate__animated animate__lightSpeedInRight'">
            - Q3/2025: {{ $t('计划三1') }}<br>
            - Q4/2025: {{ $t('计划三2') }}<br>
            - Q4/2025: {{ $t('计划三3') }}
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"DevelopPlan"
}
</script>

<style lang="scss">
.planItem{
    border-left: 1px solid #B5B5B5;
    position: relative;
    padding-left: .3rem;
    padding-bottom: .8rem;
    .planCircle{
        width: .24rem;
        height: .24rem;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        position: absolute;
        left: -0.12rem;
        top: 0;
        background-color: #000000;
        z-index: 1;
        .planDot{
            width: .14rem;
            height: .14rem;
            border-radius: 50%;
            background-color: #FFFFFF;
        }
    }
}
</style>