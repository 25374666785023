<template>
    <div class="pos-rel">
        <div class="part-gap"></div>
        <div class="flex ac partItem" :style="{left: `${left}rem`}">
            <img :src="require(`@/assets/partner/${index+14}.png`)" class="partImg" v-for="(item,index) in 13" :key="index">    
        </div>
        <div class="flex ac partItem" :style="{left: `${left1}rem`}">
            <img :src="require(`@/assets/partner/${index+14}.png`)" class="partImg" v-for="(item,index) in 13" :key="index">    
        </div>
    </div>
</template>

<script>
export default {
    name:"PartnerMoveRight",
    props:{
        speed:{
            type:Number,
            default: 0.01
        }
    },
    data(){
        return{
            left: -35.1,
            left1: 0,
            timer: null
        }
    },
    mounted(){
        this.init()
    },
    beforeDestroy(){
        clearInterval(this.timer)
        this.timer = null
        this.left = 0
        this.left1 = -35.1
    },
    methods:{
        init(){
            this.timer = setInterval(() => {
                if(this.left==0){
                    this.left1 = -35.1
                }else if(this.left1==0){
                    this.left = -35.1
                }
                this.left = this.bcadd(this.left, this.speed)
                this.left1 = this.bcadd(this.left1, this.speed)
            }, 16.67);
        },
        bcadd(a, b) {
            var c, d, e;
            try {
                c = a.toString().split(".")[1].length
            } catch (f) {
                c = 0
            }
            try {
                d = b.toString().split(".")[1].length
            } catch (f) {
                d = 0
            }
            return e = Math.pow(10, Math.max(c, d)), (this.bcmul(a, e) + this.bcmul(b, e)) / e
        },
        bcmul(a, b){
            var c = 0,
            d = a.toString(),
            e = b.toString();
            try {
                c += d.split(".")[1].length
            } catch(f) {}
            try {
                c += e.split(".")[1].length
            } catch(f) {}
            return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c)
        },
    }
}
</script>

<style>
.part-gap{
    width: 100%;
    height: 1rem;
}
.partImg{
    width: 2.4rem;
    height: 1rem;
    margin-right: .3rem;
}
.partItem{
    transition: all;
    position: absolute;
    top: 0;
}
</style>