import { getLang } from '@/utils/storage'

const state = {
    lang: getLang(),
    navCur: 0
}

const mutations = {
    SET_NAVCUR(state, payload) {
        state.navCur = payload
    },
    SET_LANG(state, payload) {
		state.lang = payload
	}
}

const actions = {
    
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

