<template>
    <div>
        <div class="navbar main-p flex jb ac">
            <img src="@/assets/logo.png" class="logo flex-s cur" @click="navChange(0)">
            <div class="flex-1 flex ja ac overHide pc">
                <div class="navbarItem size-18 cur" v-for="(item,index) in navbar" :key="item.value" @click="navChange(index)">{{ item.name }}</div>
                <!-- <el-dropdown trigger="click" @command="handleCommand">
                    <div class="navbarLang flex ac cur">
                        <img src="@/assets/lang.png" class="img-30 mr-8">
                        <div class="navbarLangBox flex ac">
                            <div class="size-16">{{ lang.name }}</div>
                            <img src="@/assets/arrowDown.png" class="arrowDown">
                        </div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="item.value" v-for="item in locals" :key="item.value">{{ item.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
            <div class="mobile flex ac">
                <!-- <el-dropdown trigger="click" @command="handleCommand">
                    <div class="langBox flex ac">
                        <img src="@/assets/lang1.png" class="img-30 mr-14">
                        <div class="size-20 mr-12">{{ lang.name }}</div>
                        <i class="el-icon-arrow-down size-16"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="item.value" v-for="item in locals" :key="item.value">{{ item.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
                <img src="@/assets/menu.png" class="img-56 ml-20" @click="drawer = true">
            </div>
        </div>
        <div class="navGap"></div>
        <el-carousel :interval="4000" height="59vw" indicator-position="none">
            <el-carousel-item v-for="(item,index) in 5" :key="index">
                <img :src="require(`../assets/${lang.value}/banner${index+1}.png`)" class="banner">
            </el-carousel-item>
        </el-carousel>

        <el-drawer
            :with-header="false"
            size="54%"
            custom-class="navbarMenu"
            :visible.sync="drawer"
            >
            <div class="navbarMenuBox size-24">
                <div class="navbarMenuItem flex ac" :class="{'menuAct':index==navCur}" v-for="(item,index) in navbar" :key="item.value" @click="menuClick(index)">
                    <img :src="require(`@/assets/menu${index+1}.png`)" class="img-30">
                    <div class="ml-22">{{ item.name }}</div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { setLang } from '@/utils/storage'
export default {
    name:"NavBar",
    data(){
        return {
            locals:[
                {name:'English',value:'en'},
                // {name:'中文',value:'zh'}
            ],
            drawer: false
        }
    },
    computed:{
        navbar(){
            return [
                {name:this.$t('首页'),value:'Home'},
                {name:this.$t('关于我们'),value:'About'},
                {name:this.$t('核心优势'),value:'Core'},
                {name:this.$t('产品与服务'),value:'Product'},
                {name:this.$t('技术实力'),value:'Tech'},
                {name:this.$t('通证数字化生态'),value:'Token'},
                {name:this.$t('发展规划'),value:'Plan'},
                {name:this.$t('合作伙伴'),value:'Part'},
                {name:this.$t('联系我们'),value:'Contact'}
            ]
        },
        lang(){
            return this.locals.find(item => item.value == this.$store.state.user.lang)
        },
        navCur(){
            return this.$store.state.user.navCur
        }
    },
    methods:{
        handleCommand(command) {
            if(command == this.lang.value)return
            this.$i18n.locale = command
            setLang(command)
            this.$store.commit('user/SET_LANG', command)
        },
        navChange(index){
            if(index == this.navCur)return
            this.$store.commit('user/SET_NAVCUR', index)
            this.$emit("scroll", this.navbar[index].value)
        },
        menuClick(index){
            if(index == this.navCur)return
            this.$store.commit('user/SET_NAVCUR', index)
            this.drawer = false
            setTimeout(() => {
                this.$emit("scroll", this.navbar[index].value)
            }, 300);
        }
    }
}
</script>

<style lang="scss">
.navbarMenu{
    background-color: #011309 !important;
    .navbarMenuBox{
        padding: .3rem .2rem 0 .2rem;
        .navbarMenuItem{
            height: .8rem;
            border-radius: .2rem;
            background-color: #041E0F;
            color: #FFFFFF;
            padding-left: .3rem;
            margin-bottom: .1rem;
        }
        .menuAct{
            background-color: #00D15D !important;
        }
    }
}
.navbar{
    width: 100vw;
    background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .navbarItem{
        white-space: nowrap;
        &:hover{
            color: #00D15D;
        }
    }
    .navbarLang{
        height: .46rem;
        padding: 0 .08rem;
        background-color: #00FF5A;
        border-radius: .23rem;
        .navbarLangBox{
            height: .34rem;
            border-radius: .17rem;
            background-color: #000000;
            padding: 0 .1rem;
            color: #FFFFFF;
            .arrowDown{
                width: .08rem;
                height: .06rem;
                margin-left: .08rem;
            }
        }
    }
}
.banner{
    width: 100%;
}
.langBox{
    height: .52rem;
    padding: 0 .12rem;
    border: 1px solid #FFFFFF;
    border-radius: .26rem;
    color: #FFFFFF;
}
@media (min-width:768px) {
    .navbar{
        height: 1.4rem;
        .logo{
            width: 1.9rem;
            height: .54rem;
            margin-right: 1rem;
        }
    }
    .navGap{
        width: 100%;
        height: 1.4rem;
    }
}
@media (max-width:768px) {
    .navbar{
        height: .88rem;
        .logo{
            width: 1.4rem;
            height: .4rem;
            margin-right: 0;
        }
    }
    .navGap{
        width: 100%;
        height: .88rem;
    }
}
</style>