<template>
  <div class="main-p pt-200">
    <div class="text-center size-60 text-bold pb-40" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('核心优势') }}</div>
    <div class="coreItem flex flex-w" v-animate-onscroll.repeat="'animate__animated animate__bounceInRight'">
        <img src="@/assets/core1.png" class="coreImg flex-s">
        <div class="flex-1 coreRight">
            <div class="size-26 text-bold">{{ $t('安全与合规') }}</div>
            <div class="size-20 coreP mt-40">{{ $t('安全与合规...') }}</div>
        </div>
    </div>
    <div class="coreItem flex flex-w" v-animate-onscroll.repeat="'animate__animated animate__bounceInRight'">
        <img src="@/assets/core2.png" class="coreImg flex-s">
        <div class="flex-1 coreRight">
            <div class="size-26 text-bold">{{ $t('创新驱动的产品与服务') }}</div>
            <div class="size-20 coreP mt-40">{{ $t('创新驱动的产品与服务...') }}</div>
        </div>
    </div>
    <div class="coreItem flex flex-w" v-animate-onscroll.repeat="'animate__animated animate__bounceInRight'">
        <img src="@/assets/core3.png" class="coreImg flex-s">
        <div class="flex-1 coreRight">
            <div class="size-26 text-bold">{{ $t('多元化生态体系') }}</div>
            <div class="size-20 coreP mt-40">{{ $t('多元化生态体系...') }}</div>
        </div>
    </div>
    <div class="coreItem flex flex-w" v-animate-onscroll.repeat="'animate__animated animate__bounceInRight'">
        <img src="@/assets/core4.png" class="coreImg flex-s">
        <div class="flex-1 coreRight">
            <div class="size-26 text-bold">{{ $t('国际权威审计机构保驾护航') }}</div>
            <div class="size-20 coreP mt-40">{{ $t('国际权威审计机构保驾护航...') }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"CoreStrengths"
}
</script>

<style lang="scss">
.coreItem{
    background: linear-gradient(to right, #09331C , rgba(9, 51, 28, 0));
    .coreP{
        color: #809C8D;
        line-height: .4rem;
    }
}
@media (min-width:768px) {
    .coreItem{
        padding: .75rem 1rem .75rem .86rem;
        margin-top: .6rem;
       .coreImg{
            width: 2.2rem;
            height: 2.2rem;
        } 
    }
    
    .coreRight{
        margin-left: 1.18rem;
    }
}
@media (max-width:768px) {
    .coreItem{
        padding: .3rem;
        margin-top: .3rem;
       .coreImg{
            width: 1rem;
            height: 1rem;
        } 
    }
    .coreRight{
        margin-left: .1rem;
        padding-top: .1rem;
    }
}
</style>