<template>
  <div class="main-p pt-200">
    <div class="text-center size-60 text-bold" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('技术实力1') }}</div>
    <div class="size-24 text-center mt-78 line-height-48" style="color: #9EABA3;" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{ $t('技术实力...') }}</div>
    <div class="flex jb flex-w mt-166">
        <div class="techItem">
            <img src="@/assets/tech1.png" class="techImg" v-animate-onscroll.repeat="'animate__animated animate__rotateInUpLeft'">
            <div class="size-20" style="color: #0AB845;" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('多层次的技术架构') }}</div>
            <div class="size-16 line-height-32 mt-30" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
                <span>{{ $t('智能合约和自动化系统：') }}</span>
                <br>
                <span class="color-7d">{{ $t('智能合约和自动化系统...') }}</span>
            </div>
        </div>
        <div class="techItem">
            <img src="@/assets/tech2.png" class="techImg" v-animate-onscroll.repeat="'animate__animated animate__rotateInUpLeft'">
            <div class="size-20" style="color: #0AB845;" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('创新驱动的技术产品') }}</div>
            <div class="size-16 line-height-32 mt-30" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
                <span>{{ $t('ARK AI Swap:') }}</span>
                <br>
                <span class="color-7d">{{ $t('ARK AI Swap...') }}</span>
                <br>
                <span>{{ $t('云算力挖矿设备：') }}</span>
                <br>
                <span class="color-7d">{{ $t('云算力挖矿设备...') }}</span>
            </div>
        </div>
        <div class="techItem">
            <img src="@/assets/tech3.png" class="techImg" v-animate-onscroll.repeat="'animate__animated animate__rotateInUpLeft'">
            <div class="size-20" style="color: #0AB845;" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('跨链互操作性和去中心化金融网络（DeFi）') }}</div>
            <div class="size-16 line-height-32 mt-30" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
                <span>{{ $t('跨链桥接技术：') }} </span>
                <br>
                <span class="color-7d">{{ $t('跨链桥接技术...') }}</span>
                <br>
                <span>{{ $t('去中心化金融网络：') }}</span>
                <br>
                <span class="color-7d">{{ $t('去中心化...') }}</span>
            </div>
        </div>
        <div class="techItem techItem1">
            <img src="@/assets/tech4.png" class="techImg" v-animate-onscroll.repeat="'animate__animated animate__rotateInUpLeft'">
            <div class="size-20" style="color: #0AB845;" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('强大的安全审计能力') }}</div>
            <div class="size-16 line-height-32 mt-30" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
                <span>{{ $t('与ScaleBit、MoveBit及母品牌BitsLab合作：') }}</span>
                <br>
                <span class="color-7d">{{ $t('与品牌合作...') }}</span>
            </div>
        </div>
        <div class="techItem techItem1">
            <img src="@/assets/tech5.png" class="techImg" v-animate-onscroll.repeat="'animate__animated animate__rotateInUpLeft'">
            <div class="size-20" style="color: #0AB845;" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('区块链技术创新') }}</div>
            <div class="size-16 line-height-32 mt-30" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
                <span>{{ $t('RWA（实物资产上链）：') }}</span>
                <br>
                <span class="color-7d">{{ $t('RWA（实物资产上链）...') }}</span>
                <br>
                <span>{{ $t('数据可用性采样（DAS）和Danksharding：') }}</span>
                <br>
                <span class="color-7d">{{ $t('数据可用性采样...') }}</span>
            </div>
        </div>
        <div class="techItem techItem1">
            <img src="@/assets/tech4.png" class="techImg" v-animate-onscroll.repeat="'animate__animated animate__rotateInUpLeft'">
            <div class="size-20" style="color: #0AB845;" v-animate-onscroll.repeat="'animate__animated animate__slideInDown'">{{ $t('Web3基础设施支持') }}</div>
            <div class="size-16 line-height-32 mt-30" v-animate-onscroll.repeat="'animate__animated animate__slideInRight'">
                <span>{{ $t('多领域生态系统覆盖：') }}</span>
                <br>
                <span class="color-7d">{{ $t('多领域生态系统覆盖...') }}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"TechCapab"
}
</script>

<style lang="scss">
.techItem{
    margin-bottom: 1.34rem;
    padding: 2.25rem .3rem .3rem .3rem;
    border-radius: .2rem;
    border: .04rem solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #0A0C0B, #0A0C0B), linear-gradient(#38F8D4, #43EA80);
    position: relative;
    .techImg{
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: -0.6rem;
        left: 1.55rem;
        z-index: 1;
    }
}
@media (min-width:768px) {
    .techItem{
        width: 30%;
    }
    .techItem1{
        margin-bottom: 0 !important;
    }
}
@media (max-width:768px) {
    .techItem{
        width: 100%;
    }
}
</style>